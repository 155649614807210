import PropTypes from "prop-types";
import React from "react";
import { motion } from "framer-motion";

const MotionSvg = ({ path, viewBox, stroke = "#99005C", fill = "#99005C" }) => {
  const icon = {
    hidden: {
      fill: "rgba(255, 255, 255, 0)",
      pathLength: 0,
    },
    visible: {
      fill,
      pathLength: 1,
    },
  };
  return (
    <motion.svg
      aria-hidden="true"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
    >
      <motion.path
        d={path}
        variants={icon}
        initial="hidden"
        animate="visible"
        stroke={stroke}
        transition={{
          default: { duration: 5, ease: "easeInOut" },
        }}
      />
    </motion.svg>
  );
};

MotionSvg.propTypes = {
  path: PropTypes.string.isRequired,
  viewBox: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
};

export default MotionSvg;
