import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/card";
import LinkButton from "../components/LinkButton";
import LogoCloud from "../components/LogoCloud";
import MotionSvg from "../components/icons/MotionSvg";

const RubyOnRailsPage = () => {
  return (
    <Layout>
      {(linkClicked, cardVisible) => (
        <>
          <SEO keywords={[]} title="Ruby on Rails Consulting and Development" />
          <Card
            visible={cardVisible}
            header="Ruby on Rails Consulting and Development"
          >
            <figure className="w-1/3 md:w-1/5 mx-auto my-4 text-magenta-900">
              <MotionSvg
                path="M463.7 0H112.3c-4.2 0-8.1 2.2-10.3 5.8L1.7 168.6c-2.7 4.4-2.2 10.1 1.2 14l276 325.2c4.8 5.6 13.4 5.6 18.2 0l276-325.2c3.4-3.9 3.8-9.6 1.2-14L474 5.8c-2.2-3.6-6.1-5.8-10.3-5.8zm-13.6 36l74.3 124h-83L384.6 36h65.5zM345 36l56.8 124H174.1L231 36h114zm-219.1 0h65.5l-56.8 124h-83l74.3-124zM61.2 192h73L216 384 61.2 192zm112 0h229.5L288 455.8 173.2 192zM360 384l81.8-192h73L360 384z"
                viewBox="0 0 576 512"
              />
            </figure>
            <p className="text-base leading-6 text-gray-700 text-left mb-3">
              I’ve specialized in development of Ruby on Rails (RoR)
              applications as well as consulting, migrating and refactoring of
              legacy systems.
            </p>

            <p className="text-base leading-6 text-gray-700 text-left mb-3">
              I collaborate with a network of skilled fellow engineers to cover
              a wide range of workloads.
            </p>
            <p className="text-base leading-6 text-gray-700 text-left mb-3">
              Choose your project type:
            </p>
            <div className="mt-6 sm:mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <LinkButton
                url="/ruby-on-rails-new"
                onClick={linkClicked}
                fullWidth={true}
              >
                I’d like to have a new web application developed from ground up
              </LinkButton>
              <div className="mt-3 sm:mt-0">
                <LinkButton
                  url="/ruby-on-rails-legacy"
                  onClick={linkClicked}
                  fullWidth={true}
                >
                  I have an existing RoR application that needs refurbishing
                </LinkButton>
              </div>
            </div>
          </Card>
          <LogoCloud
            logos={[
              {
                name: "bullet-train",
                url: "https://bullettrain.co/",
                type: "png",
              },
              {
                name: "code-and-co",
                url: "https://www.codeandco.com/",
                type: "svg",
              },
              {
                name: "weavs",
                url: "https://www.weavs.io",
              },
              {
                name: "kreativfarm",
                url: "http://kreativ.farm/",
              },
              {
                name: "inter-pool",
                url: "https://www.inter-pool.at",
              },
            ]}
          />
        </>
      )}
    </Layout>
  );
};

export default RubyOnRailsPage;
